import React, { ReactNode } from "react"
import styled from "styled-components"

import { BaseContainer } from "../containers"
import { H2 } from "../text"

const Container = styled(BaseContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  // This owl because of no flex-gap support on Safari..
  > * + * {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;

    > * + * {
      margin-top: 4rem;
    }
  }
`

const Title = styled(H2)`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`

interface CardsContainerProps {
  children: ReactNode
  title?: string
  className?: string
}

const CardsContainer: React.FC<CardsContainerProps> = ({
  title,
  children,
  className,
}) => (
  <Container className={className}>
    {title && <Title>{title}</Title>}
    <ItemsContainer>{children}</ItemsContainer>
  </Container>
)

export default CardsContainer
