import React, { ReactNode } from "react"
import styled from "styled-components"

import { H2 } from "../text"
import { BaseContainer } from "../containers"

const Title = styled(H2)`
  margin-bottom: 2rem;
`

interface CardProps {
  title: string
  children: ReactNode
  className?: string
}

const MainCard: React.FC<CardProps> = ({ title, children, className }) => (
  <BaseContainer className={className}>
    <Title>{title}</Title>
    {children}
  </BaseContainer>
)

export default MainCard
