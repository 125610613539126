import React, { ReactNode } from "react"
import styled from "styled-components"
import { GridContainer } from "../grid"

import { H3, H4 } from "../text"

const StyledCard = styled.div`
  grid-column: 1 / span 10;
  background: ${({ theme }) => theme.colors.grey};
  padding: 2rem 1rem;
  width: 100%;
  word-wrap: break-word;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 7;
    display: grid;
    padding: 4rem;
  }
`

const InnerSubjectContainer = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`

const OuterSubjectContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: initial;
    grid-column: 9 / span 2;
  }
`

const TopBorder = styled.div`
  height: 8px;
  width: 4rem;
  background: ${({ theme }) => theme.colors.beukelaarGreenLight};
  margin-bottom: 1rem;
`

const Title = styled(H3)`
  margin-bottom: 2rem;
`

const ParagraphsContainer = styled.div`
  display: grid;
  gap: 2rem;
`

interface CardProps {
  title: string
  children: ReactNode
  subject?: string
  className?: string
}

const SecondaryCardWithSubject: React.FC<CardProps> = ({
  subject,
  title,
  children,
  className,
}) => (
  <GridContainer columns={10}>
    <StyledCard className={className}>
      {subject && (
        <InnerSubjectContainer>
          <TopBorder />
          <H4>{subject}</H4>
        </InnerSubjectContainer>
      )}
      <Title>{title}</Title>
      <ParagraphsContainer>{children}</ParagraphsContainer>
    </StyledCard>
    {subject && (
      <OuterSubjectContainer>
        <TopBorder />
        <H4>{subject}</H4>
      </OuterSubjectContainer>
    )}
  </GridContainer>
)

export default SecondaryCardWithSubject
