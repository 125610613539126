import React, { ReactNode } from "react"
import styled from "styled-components"

import { H3 } from "../text"

const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  padding: 2rem 1rem;
  width: 100%;
  word-wrap: break-word;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4rem;
  }
`

const Title = styled(H3)`
  margin-bottom: 2rem;
`

const ParagraphsContainer = styled.div`
  display: grid;
  gap: 2rem;
`

interface CardProps {
  title: string
  children: ReactNode
  className?: string
}

const SecondaryCard: React.FC<CardProps> = ({ title, children, className }) => (
  <StyledCard className={className}>
    <Title>{title}</Title>
    <ParagraphsContainer>{children}</ParagraphsContainer>
  </StyledCard>
)

export default SecondaryCard
