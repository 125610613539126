import React, { ReactNode } from "react"
import styled from "styled-components"
import { BaseContainer } from "../containers"

import { H4 } from "../text"

const LocationCardContainer = styled(BaseContainer)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  word-wrap: break-word;
`

const ContentContainer = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 5;
    margin: auto;
  }
`

const ImageContainer = styled.div`
  grid-column: 1 / span 12;
  margin: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 8 / span 5;
  }
`

const Name = styled(H4)`
  margin-bottom: 0.5rem;
`

const AddressContainer = styled.div`
  margin-bottom: 2rem;
`

const ParagraphsContainer = styled.div``

interface LocationCardProps {
  className?: string
  name: string
  street: string
  number: string
  zipCode: string
  city: string
  image: ReactNode
}

const LocationCard: React.FC<LocationCardProps> = ({
  className,
  name,
  street,
  number,
  zipCode,
  city,
  image,
  children,
}) => (
  <LocationCardContainer className={className}>
    <ContentContainer>
      <Name>{name}</Name>
      <AddressContainer>
        <p>
          {street} {number}
        </p>
        <p>
          {zipCode} {city}
        </p>
      </AddressContainer>
      <ParagraphsContainer>{children}</ParagraphsContainer>
    </ContentContainer>
    <ImageContainer>{image}</ImageContainer>
  </LocationCardContainer>
)

export default LocationCard
