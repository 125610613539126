import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"

import SEO from "../../components/seo"

import { GridContainer } from "../../components/grid"
import { PhotoHero, SimpleTitle } from "../../components/heros"
import { StaticImage } from "gatsby-plugin-image"
import { NavigationDetailCTA } from "../../components/ctas"
import { PrimaryLink } from "../../components/links"
import {
  CardsContainer,
  SecondaryCardWithSubject,
} from "../../components/cards"

const StyledCardsContainer = styled(CardsContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const LeadershipPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Leiderschap"
      description="Leiderschap begint met het leiden van jezelf. Pas dan kun je werkelijk leidinggeven aan een team en organisatie."
    />
    <GridContainer>
      <SimpleTitle>Leiderschap</SimpleTitle>
      <PhotoHero
        title="Leiderschap is een actie, niet een positie"
        subtitle="Een leider is iemand die de verantwoordelijkheid neemt om het volle potentieel van medewerkers en zijn netwerk te laten ontwikkelen. Om dit goed te kunnen doen zijn er een aantal basisvaardigheden en kenmerken cruciaal. Een leider heeft op de eerste plaats moed nodig. In een leidinggevende positie ben je kwetsbaar voor feedback, voor afkeur en falen. De hoogste bomen vangen namelijk de meeste wind. Ondanks dat gevoel van kwetsbaarheid en het bijbehorende ongemak, maakt een goede leider als het erop aankomt de juiste keuzes. Niet alleen voor zichzelf, maar juist voor de hele groep. Hiervoor is moed nodig. Het vergroten van het zelfbewustzijn is een belangrijk component. Want hoe je bent, is hoe je leidt. Leiderschap is de vaardigheid om leiding te geven aan je eigen leven, aan een groep mensen, en aan een organisatie. Om dit goed te doen is zelfbewustzijn, oog hebben voor de ander, en een duidelijke visie nodig."
        image={
          <StaticImage
            src="../../images/leiderschap.jpg"
            alt="Leiderschap"
            placeholder="blurred"
            quality={100}
          />
        }
      />
      <StyledCardsContainer title="Bekijk ons aanbod:">
        <SecondaryCardWithSubject
          title="Leiderschapsprogramma’s"
          subject="Op maat gemaakt"
        >
          <p>
            De stijl van leiderschap heeft invloed op de manier waarop de mensen
            in je organisatie hun werk uitvoeren. Er is niet één goed een
            passende leiderschapstijl voor iedere organisatie. Het is maatwerk.
            De Beukelaar Groep ontwikkelt leiderschapsprogramma’s speciaal voor
            jouw bedrijf. Ook al zal de stijl van leiderschap verschillen ten
            opzichte van andere organisaties, onze aanpak kent een duidelijke
            opbouw. Allereerst ben je leider van jezelf. Zelfreflectie en een
            goed zelfbeeld is de basis in de leiderschapsontwikkeling. Hoe jij
            bent is hoe je leidt. Leiderschap vraagt om het omgaan met angsten
            en kwetsbaarheid. Je staat voor een visie, voor een groep, voor iets
            dat groter is dan jijzelf. Jezelf kennen is dan het meest
            waardevolle instrument wat je in handen kunt hebben.
          </p>
          <p>
            Als de basis goed is kan je gaan werken aan de
            leiderschapsvaardigheden die nodig zijn bij het leiden van anderen.
            Bijvoorbeeld kennis van het effect wat jouw gedragingen hebben op de
            groep die je leidt. Daarnaast is het ontwikkelen van een
            helicopterview en het opzetten van een strategische bril nodig om
            vanuit het belang van de organisatie te kunnen opereren. Welke
            kansen liggen er nu voor me? Hoe kan ik die klant overtuigen om
            samen te gaan werken? En moet ik gaan investeren of bezuinigen? Het
            sparren over jouw strategische keuzes helpt je om een blanco blik te
            werpen op de thema’s die nu spelen in jouw organisatie.
            Leiderschapstrajecten in organisaties zijn integrale processen die
            leiden tot meer efficiënt en sterk leiderschap.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="Executive coaching"
          subject="Traject en investering in overleg"
        >
          <p>
            Met grote verantwoordelijkheden komen ook grote twijfels en
            kwetsbaarheid. Ben jij (top)manager of bestuurder en zoek jij iemand
            om op hoog niveau mee te sparren op zowel strategisch als
            persoonlijk niveau, grote kans dat we je kunnen helpen. Onze
            executive coaches hebben een bedrijfskundige achtergrond waardoor er
            gemakkelijk geschakeld kan worden tussen strategie, leiderschap en
            persoonlijke ontwikkeling.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="ACT coaching"
          subject="Telefonische intake, digitale meting en terugkoppeling van 120 minuten"
        >
          <p>
            Leiderschap begint bij het goed kennen van jezelf. Want jij alleen
            bent het instrument om je team aan te sporen, te enthousiasmeren of
            te veranderen. Als je echt een stap wil zetten als leider of
            professional dan is de ACT analyse en coaching van harte aan te
            bevelen. In tegenstelling tot andere assessments combineert dit
            assessment tekst met beeld. Dit maakt het mogelijk om onderscheid te
            maken tussen natuurlijke en aangeleerde competenties. Bovendien
            worden in dit onderzoek de innerlijke drijfveren duidelijker, op
            beelden reageren we namelijk impulsiever en meer intuiïtief, terwijl
            teksten vooral op cognitief niveau binnenkomen. Deze combinatie van
            tekst en beeld maakt ACT® (Analytical Competence Tool) effectief en
            uniek. De manier van coaching is kort en intensief, maar kan
            natuurlijk gecombineerd worden met een langer traject.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="Talenten Motivatie Analyse (TMA)"
          subject="Telefonische intake, digitale meting en terugkoppeling van 90 minuten"
        >
          <p>
            Mensen zijn het meest gemotiveerd, effectief en succesvol in werk
            dat het dichts bij hun ware aard ligt. Dit voorkeursgedrag komt
            voort uit talenten. Talenten zijn de persoonlijke eigenschappen die
            tot uitdrukking komen in alle taken die mensen goed en met plezier
            doen. Wanneer die talenten worden gebruikt komt er energie vrij en
            gaan de dingen makkelijker. In een werksituatie is het vaak moeilijk
            om je ware talent te herkennen en benoemen omdat er in een
            werksituatie altijd ook sprake is van gewenst gedrag
            (functieomschrijving) en getoond gedrag (competenties). Deze twee
            types gedrag zeggen nog niet alles over de eigen persoonlijke
            voorkeur en dus het persoonlijke talent van een persoon. Als gewenst
            gedrag en getoond gedrag in lijn zijn met persoonlijk talent zul je
            meer gemotiveerd, effectief en succesvol zijn in het werk. De TMA
            maakt het mogelijk een objectieve, opbouwende en vooral diepgaande
            analyse van drijfveren, talenten en ontwikkelmogelijkheden te maken.
            In de TMA talentanalyse worden 22 drijfveren en 44 talenten
            uitvoerig in kaart gebracht. De analyse wordt gedaan met behulp van
            een online vragenlijst bestaande uit 312 vragen. Na het invullen van
            de TMA vragenlijst worden de antwoorden geanalyseerd en ontstaat de
            zogenaamde ‘ruggengraad’ of ‘talentencirkel’ waarin snel zichtbaar
            wordt waar de talenten liggen. Je ontvangt een uitgebreid rapport
            waarin de resultaten op een gedragsbeschrijvende manier worden
            toegelicht en binnen de context geplaatst. In het validatiegesprek
            van 90 minuten onderzoek je samen met een TMA professional wat de
            uitkomst voor je betekent.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="Persoonlijke &amp; professionele coaching"
          subject="6 sessies van 75 minuten"
        >
          <p>
            Zoek niet naar geluk, zoek naar betekenis. Dan volgt het geluk
            vanzelf. Onze coaches helpen jou daarbij. Om te groeien als mens en
            professional is het wijs om zo nu en dan de ruimte te nemen om met
            een coach te sparren over je (werkende) leven. De vragen wie ben
            jij, wat wil jij, en wat kun jij staan altijd centraal. Coaching kan
            zowel binnen op een van onze vestigingen (Noordwijk of Soestdijk),
            of buiten tijdens een wandeling.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="Transformational presence"
          subject="6 sessies van 90 minuten"
        >
          <p>
            Deze vorm van coaching is gericht op de persoonlijke ontwikkeling en
            leert je hele specifieke technieken aan om in allerlei situaties te
            kunnen toepassen. Transformational presence is een methode die in
            Amerika door Alan Seal is ontwikkeld en wordt bij De Beukelaar Groep
            aangeboden in de vorm van coaching en training door een van onze
            officieel erkende trainers.
          </p>
        </SecondaryCardWithSubject>
        <SecondaryCardWithSubject
          title="24 uur met..."
          subject="24 uur inclusief verblijf, lunch, diner en ontbijt."
        >
          <p>
            Van 12 uur ’s middags tot 12 uur de volgende dag ben je samen met
            een coach van De Beukelaar Groep in een van onze coachhuisjes op
            diverse locaties. Dat betekent samen eten, samen slapen en samen
            werken aan de thema’s die voor jou van belang zijn. Een zeer
            intensieve en diepgaande coachingmethode.
          </p>
        </SecondaryCardWithSubject>
      </StyledCardsContainer>
      <NavigationDetailCTA>
        <PrimaryLink to="/diensten/organisatieontwikkeling/">
          NAAR ORGANISATIEONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/teamontwikkeling/">
          NAAR TEAMONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/diversiteit-en-inclusie/">
          NAAR DIVERSITEIT &amp; INCLUSIE
        </PrimaryLink>
      </NavigationDetailCTA>
    </GridContainer>
  </>
)

export default LeadershipPage
