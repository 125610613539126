import React, { ReactNode } from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

import { H3 } from "../text"
import ArrowRight from "../../svg/arrow-right.svg"

const StyledCard = styled(GatsbyLink)`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem 1rem 4rem 1rem;
  width: 100%;
  word-wrap: break-word;
  overflow: hidden;

  &:hover,
  &:focus {
    .corner-link {
      background: ${({ theme }) => theme.colors.beukelaarGreenLight};
      svg {
        transform: translateX(4px);
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem 2rem 4rem 2rem;
  }
`

const Title = styled(H3)`
  margin-bottom: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 2rem;
  }
`

interface LinkProps {
  size: number
}

const Link = styled.div<LinkProps>`
  background: ${({ theme }) => theme.colors.beukelaarGreen};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: calc(-${props => props.size}px / 2);
  right: calc(-${props => props.size}px / 2);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;

  svg {
    position: relative;
    top: calc(${props => props.size}px / 4 - 6px);
    left: calc(${props => props.size}px / 4 - 4px);
    fill: ${({ theme }) => theme.colors.white};
    transition: ${({ theme }) => theme.transitions.arrowOnHover};
  }
`

interface CardProps {
  title: string
  children: ReactNode
  to?: string
  className?: string
}

const MainCard: React.FC<CardProps> = ({
  title,
  children,
  to = "/diensten/organisatieontwikkeling/",
  className,
}) => (
  <StyledCard to={to} className={className}>
    <Title>{title}</Title>
    <p>{children}</p>
    <Link className="corner-link" size={128}>
      <ArrowRight />
    </Link>
  </StyledCard>
)

export default MainCard
